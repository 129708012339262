import type { DropboxAuthOptions, DropboxResponse, files } from 'dropbox'
import { Dropbox, DropboxAuth, DropboxResponseError } from 'dropbox'
import type {
	CloudConnection,
	CloudFile,
	CloudFileProvider,
	CloudProviderResult,
	PutCloudFile
} from './types'

interface DropboxPkceCodeResult {
	access_token: string
	refresh_token: string
	account_id: string
	token_type: string
	uid: string
	expires_in: number
}

interface DropboxAuthTokens {
	refreshToken: string
	accessToken: string
	clientId: string
	accessTokenExpiresAt: number
}

export class DropboxFileProvider implements CloudFileProvider {
	private readonly dropboxClient: Dropbox

	public constructor(
		private readonly tokens: DropboxAuthTokens,
		private readonly logger?: (message: string) => void
	) {
		const authOptions: DropboxAuthOptions = {
			accessToken: this.tokens.accessToken,
			refreshToken: this.tokens.refreshToken,
			clientId: this.tokens.clientId,
			accessTokenExpiresAt: new Date(this.tokens.accessTokenExpiresAt)
		}
		this.dropboxClient = new Dropbox({ auth: new DropboxAuth(authOptions) })
	}

	public async getFile(
		path: string
	): Promise<CloudProviderResult<CloudFile, Partial<DropboxAuthTokens>>> {
		const resp = await this.dropboxClient.filesDownload({ path })
		// console.log(resp)

		this.log(`DROPBOX: GET got ${resp.status}`)

		if (resp.status === 409) {
			// console.log(resp)
			throw new Error('notfound')
		}

		if (resp.status === 401) {
			throw new Error('401')
		}

		if (resp.status !== 200) {
			throw new Error(resp.status.toString())
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
		const content = await ((resp.result as any).fileBlob as Blob).text()
		const version = resp.result.rev

		const result = { content, version }
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
		const refreshedAuth = this.computeRefreshedAuth((this.dropboxClient as any).auth)

		return { result, refreshedAuth }
	}

	private computeRefreshedAuth(auth: DropboxAuth): Partial<DropboxAuthTokens> | undefined {
		const accessToken = auth.getAccessToken()
		if (accessToken !== this.tokens.accessToken) {
			this.tokens.accessToken = accessToken
			return {
				accessToken,
				accessTokenExpiresAt: auth.getAccessTokenExpiresAt().getTime()
			}
		}
		return undefined
	}

	private static getWriteMode(rev: string | null): files.UploadArg['mode'] {
		if (rev) {
			return {
				'.tag': 'update',
				update: rev
			}
		}
		return { '.tag': 'add' }
	}

	public async putFile(
		path: string,
		file: PutCloudFile
	): Promise<CloudProviderResult<string, Partial<DropboxAuthTokens>>> {
		const mode = DropboxFileProvider.getWriteMode(file.version)

		let responseStatus = 500
		try {
			const resp = await DropboxFileProvider.retryTypeErrorFailedToFetch(async () =>
				this.dropboxClient.filesUpload({
					path,
					mode,
					autorename: false,
					mute: true,
					contents: file.content
				})
			)
			this.log(`DROPBOX: GET got ${resp.status}`)
			responseStatus = resp.status

			const result = resp.result.rev
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
			const refreshedAuth = this.computeRefreshedAuth((this.dropboxClient as any).auth)

			return { result, refreshedAuth }
		} catch (error) {
			if (error instanceof DropboxResponseError) {
				responseStatus = error.status
				// console.log(error)
			} else {
				throw error
			}
		}

		this.log(`DROPBOX: Upload got ${responseStatus}`)

		if (responseStatus === 409) {
			throw new Error('conflict')
		}

		if (responseStatus === 401) {
			throw new Error('401')
		}

		if (responseStatus !== 200) {
			throw new Error(`Upload got ${responseStatus}`)
		}
		/*
    if (error.stack.includes('[507]')){
        throw new Meteor.Error(
        'bad-request',
        'Dropbox was unable to complete this action because of a storage quota limit.');
    } */
		throw new Error(responseStatus.toString())
	}

	private log(message: string): void {
		if (this.logger) {
			this.logger(message)
		}
	}

	private static async retryTypeErrorFailedToFetch<T>(action: () => Promise<T>): Promise<T> {
		let lastError: unknown
		for (let i = 0; i < 3; i += 1) {
			try {
				// eslint-disable-next-line no-await-in-loop
				return await action()
			} catch (error) {
				lastError = error
				if (error instanceof TypeError && error.message === 'Failed to fetch') {
					// eslint-disable-next-line no-continue
					continue
				} else {
					throw error
				}
			}
		}
		throw lastError
	}
}

export class DropboxOauthPkceProvider {
	private static readonly SESSION_STORAGE_KEY = 'dropboxoauth.codeVerifier'

	public constructor(
		private readonly clientId: string,
		private readonly redirectUri: string,
		private readonly logger?: (message: string) => void
	) {}

	public async redirectToLogin(): Promise<void> {
		const dropboxAuth = this.getDropboxAuth()

		const authUrl = await dropboxAuth.getAuthenticationUrl(
			this.redirectUri,
			undefined,
			'code',
			'offline',
			undefined,
			undefined,
			true
		)

		window.sessionStorage.setItem(
			DropboxOauthPkceProvider.SESSION_STORAGE_KEY,
			dropboxAuth.getCodeVerifier()
		)
		window.open(authUrl.valueOf(), '_self', 'beforeload=yes,location=yes')
	}

	public async handleOauthResponse(
		searchParams: URLSearchParams
	): Promise<CloudConnection<DropboxAuthTokens>> {
		const code = searchParams.get('code')
		if (!code) {
			throw new Error('No code in search params')
		}

		const dropboxAuth = this.getDropboxAuth()

		const codeVerifier = window.sessionStorage.getItem(DropboxOauthPkceProvider.SESSION_STORAGE_KEY)
		if (!codeVerifier) {
			throw new Error('No dropbox codeVerifier in session storage')
		}
		dropboxAuth.setCodeVerifier(codeVerifier)

		const accessTokenResponse = (await dropboxAuth.getAccessTokenFromCode(
			this.redirectUri,
			code
		)) as DropboxResponse<DropboxPkceCodeResult>
		// console.log(accessTokenResponse)

		window.sessionStorage.removeItem(DropboxOauthPkceProvider.SESSION_STORAGE_KEY)

		const tokens: DropboxAuthTokens = {
			refreshToken: accessTokenResponse.result.refresh_token,
			accessToken: accessTokenResponse.result.access_token,
			clientId: this.clientId,
			accessTokenExpiresAt: Date.now() + accessTokenResponse.result.expires_in * 1000
		}

		return { id: accessTokenResponse.result.account_id, auth: tokens }

		// const testInstance = new Dropbox(tokens)

		// await (await testInstance.filesGetMetadata({ path: '/todo/todo.txt' }))

		/* dropboxAuth.setAccessToken(accessTokenResponse.result.access_token)
				var dbx = new Dropbox.Dropbox({
					auth: dbxAuth
				}) */
	}

	private getDropboxAuth(): DropboxAuth {
		return new DropboxAuth({
			clientId: this.clientId
		})
	}
}
