import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import AppRoutes from 'AppRoutes'
import BackgroundSync from 'BackgroundSync'
import { ErrorBoundary } from 'components/ErrorBoundary'
import LoadingOrError from 'components/LoadingOrError'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import appTheme from 'theme'
import { persistor, store } from './store'

export default function App(): React.ReactElement {
	return (
		<ErrorBoundary>
			<Provider store={store}>
				<PersistGate loading={<LoadingOrError />} persistor={persistor}>
					<ThemeProvider theme={appTheme}>
						<CssBaseline />
						<BackgroundSync />
						<AppRoutes />
					</ThemeProvider>
				</PersistGate>
			</Provider>
		</ErrorBoundary>
	)
}
