import type { TodoItem } from 'lib/todotxtformat'
import { useMemo } from 'react'
import type { TypedUseSelectorHook } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import type { ViewDefinition } from './model'
import { makeSelectViewTodos } from './selectors'
import type { AppDispatch, RootState } from './store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export function useTodosView(viewDefinition: ViewDefinition): TodoItem[] {
	const selectViewTodos = useMemo(makeSelectViewTodos, [])

	const todos = useAppSelector(state => selectViewTodos(state, viewDefinition))

	return todos
}
