import type { TodoItem } from '.'
import type {
	TodoOperation,
	TodoOperationApplication,
	TodoPriority
} from './model';
import {
	buildTodoItem,
	TodoOperationType
} from './model'

export default class TodoFileSerializer {
	static todoItemFromRaw(raw: string, lineNumber: number): TodoItem {
		// check(fileSetId, String);
		// check(userId, String);
		// check(raw, String);
		// check(lineNumber, Number);

		const todo = buildTodoItem({
			raw,
			lineNumber
		})
		TodoFileSerializer.fillComputedPropertiesFromRaw(todo)
		return todo
	}

	static _todayInYyyyMmDd(): string {
		return TodoFileSerializer._dateInYyyyMmDd(new Date())
	}

	static _dateInYyyyMmDd(date: Date): string {
		const dateStr =
			`${date.getFullYear().toString() 
			}-${ 
			TodoFileSerializer._zeroPad(date.getMonth() + 1, 2) 
			}-${ 
			TodoFileSerializer._zeroPad(date.getDate(), 2)}`

		return dateStr
	}

	static buildRawFromTodo(todo: TodoItem): string {
		let raw = ''

		if (todo.isCompleted) {
			raw += 'x '
		}

		if (todo.completedDate) {
			raw += `${todo.completedDate  } `
		}

		if (todo.priority) {
			raw += `(${  todo.priority  }) `
		}

		if (todo.body) {
			raw += `${todo.body.trim()  } `
		}

		for (const project of todo.projects) {
			raw += `+${  project  } `
		}

		for (const context of todo.contexts) {
			raw += `@${  context  } `
		}

		if (todo.dueDate) {
			raw += `due:${  todo.dueDate  } `
		}

		return raw.trim()
	}

	private static fillComputedPropertiesFromRaw(todo: TodoItem): void {
		let body = todo.raw
		let result

		// isCompleted
		result = TodoFileSerializer.extractFeature(
			body,
			/^(x\s\d{2,4}(?:-\d{1,2}){2})\s+/i
		)
		body = result[0]
		todo.isCompleted = !!result[1]
		todo.completedDate = todo.isCompleted && result[1] ? result[1].slice(2) : null;

		// priority
		result = TodoFileSerializer.extractFeature(body, /^\(([a-z])\)\s/i)
		body = result[0]
		todo.priority = result[1] as TodoPriority

		// dueDate
		result = TodoFileSerializer.extractFeature(body, /due:(\d{4}-\d{2}-\d{2})/i)
		body = result[0]
		todo.dueDate = result[1]

		// projects
		result = TodoFileSerializer.extractAllFeatures(body, /(?:^|\s)\+(\S+)/i)
		body = result[0]
		todo.projects = result[1]

		// contexts
		result = TodoFileSerializer.extractAllFeatures(body, /(?:^|\s)@(\S+)/i)
		body = result[0]
		todo.contexts = result[1]

		todo.body = body
	}

	private static extractFeature(
		s: string,
		re: RegExp
	): [string, string | null] {
		const match = re.exec(s)
		if (match) {
			return [this.removeRange(s, match.index, match[0].length), match[1]]
		}
		return [s, null]
	}

	private static extractAllFeatures(s: string, re: RegExp): [string, string[]] {
		const features = []
		let result = this.extractFeature(s, re)

		while (result[1]) {
			s = result[0]
			features.push(result[1] )
			result = this.extractFeature(s, re)
		}
		return [s, features]
	}

	private static removeRange(
		s: string,
		startIndex: number,
		length: number
	): string {
		return (
			s.slice(0, Math.max(0, startIndex)) + s.slice(Math.max(0, startIndex + length))
		).trim()
	}

	private static readonly _zeroPad = (num: number, numZeros: number): string => {
		const n = Math.abs(num)
		const zeros = Math.max(0, numZeros - Math.floor(n).toString().length)
		let zeroString = (10**zeros).toString().slice(1)
		if (num < 0) {
			zeroString = `-${  zeroString}`
		}

		return zeroString + n
	}

	public static setIsCompleted(todo: TodoItem, isCompleted: boolean): TodoItem {
		// todo, more robust
		if (todo.isCompleted !== isCompleted) {
			todo.raw = isCompleted ? `x ${  this._todayInYyyyMmDd()  } ${  todo.raw}` : todo.raw.replace(/^x\s\d{2,4}(?:-\d{1,2}){2}\s+/i, '');
			todo.isCompleted = isCompleted
		}
		return todo
	}

	public fromFile(fileContents: string): TodoItem[] {
		const todos = fileContents
			.split('\n')
			.map(line => line.trim())
			.filter(line => !!line)
			.map((raw, lineNumber) =>
				TodoFileSerializer.todoItemFromRaw(raw, lineNumber)
			)

		return todos
	}

	public toFile(todos: TodoItem[]): string {
		let result = ''
		const todosCopy = [...todos] // .sort() is an in-place sort, don't want to mutate redux immer state
		const sortedByLineNumber = todosCopy.sort(
			(todoA, todoB) => todoA.lineNumber - todoB.lineNumber
		)

		for (const t of sortedByLineNumber) {
			result += `${t.raw  }\n`
		}

		return result
	}

	public applyOperation(
		operation: TodoOperation,
		todos: TodoItem[]
	): { todos: TodoItem[]; operationApplication: TodoOperationApplication } {
		let newList: TodoItem[]

		switch (operation.type) {
		case TodoOperationType.SET_IS_COMPLETED: {
			newList = this.createWithReplacedItem(operation.todoId, todos, todo =>
				TodoFileSerializer.setIsCompleted(todo, operation.isCompleted)
			)
		
		break;
		}
		case TodoOperationType.DELETE_TODO: {
			// this leaves missing line numbers, does that mattter?
			newList = todos.filter(t => t.id != operation.todoId)
		
		break;
		}
		case TodoOperationType.UPSERT_TODO: {
			const opTodo = operation.todo
			if (todos.find(t => t.id === opTodo.id)) {
				newList = this.createWithReplacedItem(
					operation.todo.id,
					todos,
					todo => {
						const updated = {
							...todo,
							body: opTodo.body,
							priority: opTodo.priority,
							contexts: opTodo.contexts,
							projects: opTodo.projects
						}
						updated.raw = TodoFileSerializer.buildRawFromTodo(operation.todo)
						return updated
					}
				)
			} else {
				const newLineNumber = Math.max(...todos.map(t => t.lineNumber)) + 1
				const newTodo = buildTodoItem({
					...operation.todo,
					lineNumber: newLineNumber,
					raw: TodoFileSerializer.buildRawFromTodo(operation.todo)
				})
				newList = [...todos, newTodo]
			}
		
		break;
		}
		default: {
			throw new Error('unknown operation')
		}
		}

		return { todos: newList, operationApplication: { operation } }
	}

	private createWithReplacedItem(
		todoId: string,
		todos: TodoItem[],
		apply: (todo: TodoItem) => TodoItem
	) {
		const targetIndex = todos.findIndex(t => t.id === todoId)
		if (targetIndex === -1) {
			throw new Error()
		}
		const originalTodo = todos[targetIndex]

		let modifiedItem = buildTodoItem(originalTodo)
		modifiedItem = apply(modifiedItem)

		const newList = [...todos
			.slice(0, targetIndex), modifiedItem]
			.concat(todos.slice(targetIndex + 1, todos.length))

		return newList
	}
}
