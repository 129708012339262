import type { TodoItem } from './model'

export interface TodoTag {
	type: string
	id: string
	name: string
	occurrences: number
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class TagUtil {
	public static aggregateContexts(todos: TodoItem[], caseSensitive = false): TodoTag[] {
		return TagUtil.sortTags(TagUtil.aggregateTags(todos, todo => todo.contexts, '@', caseSensitive))
	}

	public static aggregateProjects(todos: TodoItem[], caseSensitive = false): TodoTag[] {
		return TagUtil.sortTags(TagUtil.aggregateTags(todos, todo => todo.projects, '+', caseSensitive))
	}

	public static aggregateTags(
		todos: TodoItem[],
		accessor: (item: TodoItem) => string[],
		type: string,
		caseSensitive = false
	): TodoTag[] {
		let tags = new Map<string, TodoTag>()

		for (const todo of todos) {
			for (const tag of accessor(todo)) {
				const id = type + tag
				if (tags.has(id)) {
					const t = tags.get(id)
					if (t) {
						t.occurrences += 1
					}
				} else {
					tags.set(id, { type, name: tag, id, occurrences: 1 })
				}
			}
		}

		if (!caseSensitive) {
			tags = TagUtil.collapseDifferentCasing(tags)
		}

		const tagsArr: TodoTag[] = []
		for (const [, todoTag] of tags) {
			tagsArr.push(todoTag)
		}

		return tagsArr
	}

	public static filterTagByType(todoTags: TodoTag[], type: string): TodoTag[] {
		const contexts: TodoTag[] = []
		for (const tag of todoTags) {
			if (tag.type === type) {
				contexts.push(tag)
			}
		}
		return contexts
	}

	public static sortTags(tags: TodoTag[]): TodoTag[] {
		return tags.sort(TagUtil.compareTagsByOccurrences)
	}

	public static compareTagsByOccurrences = (tagA: TodoTag, tagB: TodoTag): number =>
		tagB.occurrences - tagA.occurrences

	private static collapseDifferentCasing(tags: Map<string, TodoTag>): Map<string, TodoTag> {
		const groups: Map<string, TodoTag[]> = new Map<string, TodoTag[]>()

		for (const [, tag] of tags) {
			const caseInvariant = tag.id.toLowerCase()
			if (groups.has(caseInvariant)) {
				const g = groups.get(caseInvariant)
				if (g) {
					g.push(tag)
				}
			} else {
				groups.set(caseInvariant, [tag])
			}
		}

		const result = new Map<string, TodoTag>()
		for (const [, group] of groups) {
			const sorted = TagUtil.sortTags(group)
			const first = sorted[0]
			for (const rest of sorted.slice(1)) {
				first.occurrences += rest.occurrences
			}
			result.set(first.id, first)
		}
		return result
	}
}
