/* eslint-disable class-methods-use-this */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-parameter-properties */
import { diff3Merge } from 'node-diff3'
import type { CloudFile, CloudFileProvider } from '../fileproviders'
import type { TodoItem } from './model'
import TodoFileSerializer from './todoFormat'
// import { logger } from 'src/app/logger';

export interface SaveResult {
	todos: TodoItem[] | null
	cloudFile: CloudFile
	hadConflict: boolean
	hadUnresolvableConflict: boolean
	refreshedAuth: any
}

export class ConflictResolvingSaver {
	private readonly serializer = new TodoFileSerializer()

	public constructor(
		private readonly cloudFileProvider: CloudFileProvider,
		private readonly logger?: (message: string) => void
	) {}

	public async saveTodos(
		path: string,
		originalVersion: string,
		todos: TodoItem[],
		originalContent: string
	): Promise<SaveResult> {
		let shouldContinue = true
		let version = originalVersion
		let intermediateResolution: SaveResult
		// TODO doesn't allows send up refreshedAuth, only when no conflict

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		while (shouldContinue) {
			shouldContinue = false

			let content = this.serializer.toFile(todos)
			try {
				const { result, refreshedAuth } = await this.cloudFileProvider.putFile(path, {
					content,
					version
				})

				return {
					hadConflict: false,
					hadUnresolvableConflict: false,
					cloudFile: { content, version: result },
					todos:
						version === originalVersion
							? todos // this is really just an optimization to not allocate and to allow object refequals prevention of ui update
							: this.conserveTodosAndIds(todos, this.serializer.fromFile(content)),
					refreshedAuth
				}
			} catch (error) {
				if (error instanceof Error) {
					if (error.message === 'conflict') {
						this.log('CONFLICT: Got conflict attempting to write file to file provider')
						intermediateResolution = await this.attemptResolve(path, content, originalContent)
						if (!intermediateResolution.hadUnresolvableConflict) {
							this.log(
								'CONFLICT: Server-side changes can be merged with local changes without conflict.'
							)
							;({ content, version } = intermediateResolution.cloudFile)
							shouldContinue = true
						} else {
							return intermediateResolution
						}
					} else {
						throw error
					}
				}
			}
		}

		throw new Error('unreachable code')
	}

	private async attemptResolve(
		path: string,
		content: string,
		originalContent: string
	): Promise<SaveResult> {
		// get latest from server = B
		// need to get base O - either store it or get it again from the server
		const { result: conflictingFile, refreshedAuth } = await this.cloudFileProvider.getFile(path)

		const diff3Result = diff3Merge(conflictingFile.content, originalContent, content)
		this.log(`CONFLICT: Finished diff3 on AOB changes. result has length ${diff3Result.length}`)

		// eslint-disable-next-line @typescript-eslint/no-magic-numbers, @typescript-eslint/no-unnecessary-condition
		if (diff3Result.length === 1 && diff3Result[0].ok) {
			const merged = diff3Result[0].ok
			const resolvedResult = {
				hadConflict: true,
				hadUnresolvableConflict: false,
				cloudFile: {
					content: merged.join(''),
					version: conflictingFile.version
				},
				todos: null,
				refreshedAuth
			}
			this.log(`CONFLICT: diff3 ok ${diff3Result.length}`)
			// console.log(diff3Result)
			console.log(resolvedResult)

			// TODO - this would sometimes revert
			// return resolvedResult
		}

		return {
			hadConflict: true,
			hadUnresolvableConflict: true,
			cloudFile: conflictingFile,
			todos: null,
			refreshedAuth
		}
	}

	// Attempt to match up new TodoItems with previous after conflict resolution
	private conserveTodosAndIds(source: TodoItem[], destination: TodoItem[]): TodoItem[] {
		// Build a lookup map using the raw (unparsed line string) of each item
		const rawMap = new Map<string, TodoItem>()
		for (const sourceItem of source) {
			rawMap.set(sourceItem.raw, sourceItem)
		}

		const result = []
		for (const destinationItem of destination) {
			const match = rawMap.get(destinationItem.raw)
			let toAdd = destinationItem

			if (match) {
				if (match.lineNumber === destinationItem.lineNumber) {
					// if line number also matches, just re-use the previous object
					toAdd = match
				} else {
					// if the todo item was moved to a different line, just re-use the ID
					destinationItem.id = match.id
				}

				// Remove this item from consideration for other matches
				rawMap.delete(destinationItem.raw)
			}

			result.push(toAdd)
		}

		return result
	}

	private log(message: string) {
		if (this.logger) {
			this.logger(message)
		}
	}
}
