import { logger } from 'lib/logger'
import type { ReactElement } from 'react'
import { useEffect, useLayoutEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { syncBegin } from 'store/reducers'

const useEventListener = (
	eventName: string,
	element: EventTarget | null,
	handler: EventListener,
	options?: AddEventListenerOptions
): void => {
	const savedHandler = useRef<EventListener>(handler)
	const { capture, passive, once } = options ?? {}

	useLayoutEffect(() => {
		savedHandler.current = handler
	}, [handler])

	useEffect(() => {
		const isSupported = element?.addEventListener
		if (!isSupported) {
			return
		}

		const eventListener = (event: Event): void => savedHandler?.current(event) // eslint-disable-line @typescript-eslint/no-unnecessary-condition

		const opts = { capture, passive, once }
		element.addEventListener(eventName, eventListener, opts)
		// console.log('attached eventListener to window')
		return (): void => {
			element.removeEventListener(eventName, eventListener, opts)
			// console.log('removed eventListener to window')
		}
	}, [eventName, element, capture, passive, once])
}

function useInterval(callback: () => void, delay: number | null): void {
	const savedCallback = useRef(callback)

	// Remember the latest callback if it changes.
	useLayoutEffect(() => {
		savedCallback.current = callback
	}, [callback])

	// Set up the interval.
	useEffect(() => {
		// Don't schedule if no delay is specified.
		// Note: 0 is a valid value for delay.
		if (!delay && delay !== 0) {
			return
		}

		const id = setInterval(() => savedCallback.current(), delay)
		console.log(`setInterval ${id}`)

		return () => {
			clearInterval(id)
			console.log(`clearInterval ${id}`)
		}
	}, [delay])
}

export default function BackgroundSync(): ReactElement | null {
	const syncId = useAppSelector(state => state.synchronization.syncId)
	const dispatch = useAppDispatch()

	useEffect(() => {
		const newSyncId = syncId === '' ? undefined : syncId
		dispatch(
			syncBegin({
				syncId: newSyncId,
				skipIfSyncedSinceMs: 10 * 60 * 1000 // 10 minutes
			})
		)
	}, [])

	useEventListener('visibilitychange', window, () => {
		logger.verbose(
			`BACKGROUND_SYNC: visibilitychange - WillDispatch=${!document.hidden.toString()}, hidden=${
				document.hidden
			}, visibilityState=${document.visibilityState}` + ` hasFocus=${document.hasFocus()}`
		)
		if (!document.hidden) {
			dispatch(
				syncBegin({
					syncId: undefined,
					skipIfSyncedSinceMs: 10 * 60 * 1000 // 10 minutes
				})
			)
		}
	})

	useInterval(() => {
		logger.verbose(
			`BACKGROUND_SYNC: Interval - WillDispatch=${!document.hidden}, hidden=${
				document.hidden
			}, visibilityState=${document.visibilityState}` + ` hasFocus=${document.hasFocus()}`
		)
		if (!document.hidden) {
			dispatch(
				syncBegin({
					syncId: undefined,
					skipIfSyncedSinceMs: 10 * 60 * 1000 // 10 minutes
				})
			)
		}
	}, 10 * 60 * 1000)

	return null
}
