/* eslint-disable class-methods-use-this */

import type { TodoItem } from './model'

export type FilterASTNode = FilterASTEvaluationNode | FilterASTOperatorNode

interface FilterASTOperatorNode {
	type: 'op'
	op: 'and' | 'or'
	nodes: FilterASTNode[]
}

interface FilterASTEvaluationNode {
	type: 'eval'
	field: 'contexts' | 'isCompleted' | 'projects' | 'true'
	op: 'contains' | 'equals' | 'notcontains'
	val: boolean | string | null
}

export const NO_FILTER: FilterASTNode = {
	type: 'eval',
	field: 'true',
	op: 'equals',
	val: true
}

interface IFilterExpressionParser {
	parseFilterExpression: (filterExpression: string) => FilterASTNode

	serializeFilterExpression: (filterAst: FilterASTNode) => string
}

interface ITodosListInMemoryFilterer {
	filterTodos: (todos: TodoItem[], filterAst: FilterASTNode) => TodoItem[]

	evaluateTodo: (todo: TodoItem, filterAst: FilterASTNode) => boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class FilterExpressionParser implements IFilterExpressionParser {
	public parseFilterExpression(filterExpression: string): FilterASTNode {
		if (filterExpression === '!isCompleted') {
			return { type: 'eval', field: 'isCompleted', op: 'equals', val: false }
		}
		if (filterExpression === 'isCompleted') {
			return { type: 'eval', field: 'isCompleted', op: 'equals', val: true }
		}
		if (filterExpression === '') {
			return NO_FILTER
		}
		throw new Error('cannot parse yet')
	}

	public serializeFilterExpression(filterAst: FilterASTNode): string {
		if (filterAst.type === 'eval') {
			if (
				filterAst.field === 'isCompleted' &&
				filterAst.op === 'equals' &&
				filterAst.val === false
			) {
				return '!isCompleted'
			}
			if (
				filterAst.field === 'isCompleted' &&
				filterAst.op === 'equals' &&
				filterAst.val === true
			) {
				return 'isCompleted'
			}
			if (filterAst.field === 'true' && filterAst.op === 'equals' && filterAst.val === true) {
				return ''
			}
		}
		throw new Error('cannot serialize yet')
	}
}

export class TodosListInMemoryFilterer implements ITodosListInMemoryFilterer {
	public filterTodos(todos: TodoItem[], filterAst: FilterASTNode): TodoItem[] {
		return todos.filter(todo => this.evaluateTodo(todo, filterAst))
	}

	// eslint-disable-next-line consistent-return
	public evaluateTodo(todo: TodoItem, filterAst: FilterASTNode): boolean {
		// eslint-disable-next-line default-case
		switch (filterAst.type) {
			case 'eval':
				switch (filterAst.op) {
					case 'equals':
						switch (filterAst.field) {
							case 'isCompleted':
								return todo.isCompleted === filterAst.val
							case 'true':
								return !!filterAst.val
							default:
								throw new Error(`cannot evaluate "${filterAst.op}}" yet`)
						}
					case 'contains':
						switch (filterAst.field) {
							case 'contexts':
								// eslint-disable-next-line unicorn/prefer-includes
								return todo.contexts.some(t => t === filterAst.val)
							case 'projects':
								// eslint-disable-next-line unicorn/prefer-includes
								return todo.projects.some(t => t === filterAst.val)
							default:
								throw new Error(`cannot evaluate contains "${filterAst.field}}" yet`)
						}
					default:
						throw new Error('cannot evaluate "op" yet')
				}
			case 'op':
				throw new Error('cannot evaluate "op" yet')
		}
	}
}
