import type { EntityState } from '@reduxjs/toolkit'
import type { FilterASTNode } from 'lib/todotxtformat/filtering'
import type { TodoPriority } from 'lib/todotxtformat/model'
import type { TodoItem, TodoOperationApplication } from '../lib/todotxtformat'

type PriorityAliases = { [key in TodoPriority]: string }

export interface ViewPreferences {
	defaultPriority: TodoPriority
	defaultProjects: string[]
	defaultContexts: string[]
	priorityAliases: Partial<PriorityAliases>
}

export interface ViewDefinition {
	id: string
	name: string
	filter: FilterASTNode

	preferences: Partial<ViewPreferences>
}

// Not actually in the state, but is a selector result
export interface ViewInstructions extends ViewDefinition {
	preferences: ViewPreferences
}

export interface UserSettingsState {
	todoFilePath: string
	// useVirtualScroll: boolean
	defaultViewPreferences: ViewPreferences
}

export interface UserState {
	name: string
	accessToken: string
	refreshToken: string
	accessTokenExpiresAt: number
	clientId: string
	settings: UserSettingsState
}

export interface AppliedTodoOperationSet {
	localRevisionId: string
	remoteRevisionId?: string
	localTime: number
	operationApplications: TodoOperationApplication[]
}

export interface SynchronizationState {
	inProgress: boolean
	syncId: string
	localRevisionId: string | null
	remoteRevisionId: string | null
	lastCompleteTime: number
	todos: TodoItem[] | null
	lastSyncedTodoFileContent: string | null
}

export interface InterfaceState {
	lastViewDefinitionId: string
}

export interface AppState {
	schemaVersion: number
	list: TodoItem[]
	user?: UserState
	// router?: any
	history: AppliedTodoOperationSet[]
	synchronization: SynchronizationState
	interface: InterfaceState

	viewDefinitions: EntityState<ViewDefinition>
}

/*
# Mutating the list
Action is a list of ops => store these in a list for undo?
something to transform a list of ops into a list of string insert/deletes (also their inverse for undo?)
opgroup: listop[], strop[], localversion, remoteversion
listopgroup: listop[], localVersion: string
listop: enum type,
strop: type: 'insert' | 'delete', pos: int, val: str
'ComputeTransform' listop[] => strop[] + TodosCollection

*/

export const INITIAL_DEFAULT_VIEW_PREFERENCES: ViewPreferences = {
	defaultPriority: 'A',
	defaultContexts: [],
	defaultProjects: [],
	priorityAliases: {
		A: 'A - Next 30m',
		B: 'B - Next 1h',
		C: 'C - Next 2h',
		D: 'D - Next 4h',
		E: 'E - Next 8h',
		F: 'F - Next 24h',
		G: 'G - Next 48h',
		H: 'H - Next 7d',
		I: 'I - Next 14d',
		J: 'J - Next 1 month',
		K: 'K - Next 2 months',
		L: 'L - Next 4 months',
		M: 'M - Next 6 months',
		N: 'N - Next 1 year',
		O: 'O - Next 2 years',
		P: 'P - Next 5 years',
		Q: 'Q - Someday'
	}
}

export const INITIAL_USER_SETTINGS: UserSettingsState = {
	// useVirtualScroll: false,
	todoFilePath: '/todo/todo.txt',
	defaultViewPreferences: INITIAL_DEFAULT_VIEW_PREFERENCES
}

export const INITIAL_STATE: AppState = {
	/*
  text file : string
  archive file: string
  parsed objects
    [todo]
  oplog[opgroup]
    opgroup[op]

   */
	schemaVersion: 2,
	list: [],
	user: undefined,
	history: [],
	synchronization: {
		inProgress: false,
		syncId: '',
		localRevisionId: null,
		remoteRevisionId: null,
		todos: null,
		lastCompleteTime: 0,
		lastSyncedTodoFileContent: null
	},
	interface: {
		lastViewDefinitionId: 'all'
	},
	viewDefinitions: {
		ids: [],
		entities: {}
	}
}
