import { createTheme } from '@mui/material/styles'

const appTheme = createTheme({
	palette: {
		primary: {
			main: '#16a085'
		}
		// #ffc107
	}
})

export default appTheme
