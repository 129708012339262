import { createSelector } from '@reduxjs/toolkit'
import type { TodoItem } from 'lib/todotxtformat'
import { NO_FILTER, TodosListInMemoryFilterer } from 'lib/todotxtformat/filtering'
import { TagUtil } from 'lib/todotxtformat/tags'
import { sortTodos } from '../lib/todotxtformat/sorting'
import type {
	AppState,
	ViewDefinition,
	ViewInstructions,
	ViewPreferences
} from './model';
import {
	INITIAL_DEFAULT_VIEW_PREFERENCES
} from './model'
import { viewDefinitionsSelectors } from './viewDefinitions'

export const selectProjects = createSelector(
	(state: AppState): TodoItem[] => state.list,
	(list: TodoItem[]) => TagUtil.aggregateProjects(list, false)
)

export const selectContexts = createSelector(
	(state: AppState): TodoItem[] => state.list,
	(list: TodoItem[]) => TagUtil.aggregateContexts(list, false)
)

export const selectViewPreferences = createSelector(
	(state: AppState): ViewPreferences | undefined => state.user?.settings.defaultViewPreferences,
	userPreferences => userPreferences ?? INITIAL_DEFAULT_VIEW_PREFERENCES
)

export const selectLastViewDefinitionId = createSelector(
	(state: AppState): string => state.interface.lastViewDefinitionId,
	lastViewDefinitionId => lastViewDefinitionId
)

export const makeSelectViewTodos = () =>
	createSelector(
		(state: AppState) => state.list,
		(_: AppState, viewDefinition: ViewDefinition) => viewDefinition,
		(todos, viewDefinition) => {
			const filtered = viewDefinition
				? new TodosListInMemoryFilterer().filterTodos(todos, viewDefinition.filter)
				: [...todos] // copy for in-place sort

			filtered.sort(sortTodos)

			return filtered
		}
	)

export const selectViewDefinitionById = createSelector(
	[
		// Usual first input - extract value from `state`
		viewDefinitionsSelectors.selectById,
		// Take the second arg, `category`, and forward to the output selector
		(_, viewDefinitionId: string | undefined) => viewDefinitionId
	],
	// Output selector gets (`items, category)` as args
	(viewDefinition, viewDefinitionId): ViewDefinition => {
		if (!viewDefinitionId || viewDefinitionId === 'all') {
			return {
				name: 'All Todos',
				filter: NO_FILTER,
				preferences: {},
				id: 'all'
			}
		}
		if (!viewDefinition) {
			if (viewDefinitionId.startsWith('@')) {
				return {
					name: viewDefinitionId,
					filter: {
						type: 'eval',
						field: 'contexts',
						op: 'contains',
						val: viewDefinitionId.slice(1)
					},
					preferences: { defaultContexts: [viewDefinitionId.slice(1)] },
					id: viewDefinitionId
				}
			} if (viewDefinitionId.startsWith('+')) {
				return {
					name: viewDefinitionId,
					filter: {
						type: 'eval',
						field: 'projects',
						op: 'contains',
						val: viewDefinitionId.slice(1)
					},
					preferences: { defaultProjects: [viewDefinitionId.slice(1)] },
					id: viewDefinitionId
				}
			} 
				throw new Error("Can't synthesize view definition")
			
		}

		return viewDefinition
	}
)

export const selectViewInstructions = createSelector(
	[selectViewDefinitionById, (state: AppState) => state.user?.settings.defaultViewPreferences],
	({ id, name, filter, preferences: viewPreferences }, userPreferences): ViewInstructions => {
		const defaultPreferences = userPreferences ?? INITIAL_DEFAULT_VIEW_PREFERENCES

		const preferences: ViewPreferences = {
			defaultPriority: viewPreferences.defaultPriority ?? defaultPreferences.defaultPriority,
			defaultContexts: viewPreferences.defaultContexts ?? defaultPreferences.defaultContexts,
			defaultProjects: viewPreferences.defaultProjects ?? defaultPreferences.defaultProjects,
			priorityAliases: viewPreferences.priorityAliases ?? defaultPreferences.priorityAliases ?? {}
		}

		return { id, name, filter, preferences }
	}
)
