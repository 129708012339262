import LoadingOrError from 'components/LoadingOrError'
import type { ReactElement } from 'react'
import { lazy, Suspense } from 'react'
import { BrowserRouter, Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import type { UserState, ViewDefinition, ViewInstructions } from 'store/model'
import {
	selectLastViewDefinitionId,
	selectViewDefinitionById,
	selectViewInstructions
} from 'store/selectors'

const LoginPage = lazy(async () => import('components/LoginPage'))
const Layout = lazy(async () => import('components/Layout'))
const AddPage = lazy(async () => import('components/author/AddPage'))
const EditPage = lazy(async () => import('components/author/EditPage'))
const EditViewDefinitionPage = lazy(async () => import('components/EditViewDefinitionPage'))

import('components/author/AddPage')

export function useRouterViewDefinition(): ViewDefinition {
	const { viewDefinitionId } = useParams()
	const viewDefinition: ViewDefinition = useAppSelector(state =>
		selectViewDefinitionById(state, viewDefinitionId ?? 'all')
	)
	return viewDefinition
}

export function useRouterViewInstructions(): ViewInstructions {
	const { viewDefinitionId } = useParams()
	const viewInstructions = useAppSelector(state =>
		selectViewInstructions(state, viewDefinitionId ?? 'all')
	)
	return viewInstructions
}

function LoginRequiredRoutes({ user }: { user: UserState | undefined }): ReactElement {
	if (!user) {
		return <Navigate to='/app/login' replace />
	}
	return <Outlet />
}

function RouterEditPage(): ReactElement {
	const params = useParams()

	return params.todoId ? <EditPage todoId={params.todoId} /> : <LoadingOrError />
}

function LandingPage(): ReactElement {
	const lastUsedViewDefinitionId = useAppSelector(selectLastViewDefinitionId)

	return <Navigate to={`/app/view/${lastUsedViewDefinitionId}`} replace />
}

function RouteLayout(): ReactElement {
	const viewInstructions = useRouterViewInstructions()

	return <Layout viewInstructions={viewInstructions} />
}

function RouteAddPage(): ReactElement {
	const viewInstructions = useRouterViewInstructions()

	return <AddPage viewInstructions={viewInstructions} />
}

function RouteEditViewDefinitionPage(): ReactElement {
	const viewDefinition = useRouterViewDefinition()
	const viewInstructions = useRouterViewInstructions()

	return (
		<EditViewDefinitionPage viewDefinition={viewDefinition} viewInstructions={viewInstructions} />
	)
}

export default function AppRoutes(): ReactElement {
	const user = useAppSelector(state => state.user)

	return (
		<BrowserRouter>
			<Suspense fallback={<LoadingOrError />}>
				<Routes>
					<Route path='/app/login' element={<LoginPage />} />
					<Route path='/app/login/:provider' element={<LoginPage />} />

					<Route element={<LoginRequiredRoutes user={user} />}>
						<Route path='/' element={<LandingPage />} />
						<Route path='/app' element={<LandingPage />} />
						<Route path='/app/view/:viewDefinitionId' element={<RouteLayout />} />
						<Route path='/app/view/:viewDefinitionId/add' element={<RouteAddPage />} />
						<Route
							path='/app/editView/:viewDefinitionId'
							element={<RouteEditViewDefinitionPage />}
						/>
						<Route path='/app/add' element={<RouteAddPage />} />
						<Route path='/app/edit/:todoId' element={<RouterEditPage />} />
					</Route>
					<Route path='*' element={<LandingPage />} />
				</Routes>
			</Suspense>
		</BrowserRouter>
	)
}
