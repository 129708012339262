import { appInsights } from './appInsights'

class Logger {
	public verbose(message: string): void {
		Logger.trackTrace(message, 0)
		// eslint-disable-next-line no-console
		console.log.apply(
			console,
			arguments as unknown as [message?: any, ...optionalParams: any[]]
		)
		// console.log(message)
	}

	public info(message: string): void {
		Logger.trackTrace(message, 1)
		// eslint-disable-next-line no-console
		console.log.apply(
			console,
			arguments as unknown as [message?: any, ...optionalParams: any[]]
		)
	}

	public warn = (input: any): void => {
		const message = Logger.buildMessage(input)
		Logger.trackTrace(message, 2)
		// eslint-disable-next-line no-console
		console.warn(message)
	}

	public error = (input: any): void => {
		const message = Logger.buildMessage(input)
		Logger.trackTrace(message, 3)
		// eslint-disable-next-line no-console
		console.error(message)
	}

	public critical = (message: string): void => {
		Logger.trackTrace(message, 4)
		// eslint-disable-next-line no-console
		console.error(message)
	}

	private static trackTrace(message: string, level: number): void {
		appInsights.trackTrace({ message, severityLevel: level })
	}

	private static buildMessage(input: any): string {
		if (input instanceof Error) {
			appInsights.trackException({ exception: input })
			console.error(input)
			return input.message
		}

		if (input) {
			return input.toString()
		}

		return ''
	}
}

// eslint-disable-next-line import/prefer-default-export
export const logger = new Logger()
