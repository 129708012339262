import type {
	TypedAddListener,
	TypedStartListening
} from '@reduxjs/toolkit';
import {
	addListener,
	configureStore,
	createListenerMiddleware
} from '@reduxjs/toolkit'
import {
	FLUSH,
	PAUSE,
	PERSIST,
	persistReducer,
	persistStore,
	PURGE,
	REGISTER,
	REHYDRATE
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import addSagas from './listeners/listeners'
import rootReducer from './reducers'

const persistConfig = {
	key: 'root',
	storage,
	whitelist: [
		'user',
		'list',
		'history',
		'synchronization',
		'schemaVersion',
		'interface',
		'viewDefinitions'
	]
	// migrate: (state: AppState) => {
	//   if (!state) {
	//     return Promise.resolve(state);
	//   }
	//   if (!state.schemaVersion || state.schemaVersion < INITIAL_STATE.schemaVersion) {
	//     return Promise.resolve(INITIAL_STATE);
	//   }

	//   let user = state.user;
	//   if (user) {
	//     const userSettings = user.settings || {};
	//     user = { ...user, settings: { ...INITIAL_USER_SETTINGS, ...userSettings } };
	//   }
	//   return Promise.resolve({ ...state, user });
	// }
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const listenerMiddleware = createListenerMiddleware()

const reduxPersistActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]

export const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [...reduxPersistActions]
			}
		}).prepend(listenerMiddleware.middleware)
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export type AppStartListening = TypedStartListening<RootState, AppDispatch>

export type AppStartListeningParameter = Parameters<AppStartListening>[0]

export const startAppListening =
	listenerMiddleware.startListening as AppStartListening

export const addAppListener = addListener as TypedAddListener<
	RootState,
	AppDispatch
>

addSagas(startAppListening)
