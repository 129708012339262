import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { AppState, ViewDefinition } from './model'

const viewDefinitionsAdapter = createEntityAdapter<ViewDefinition>({})

const viewDefinitionsSlice = createSlice({
	name: 'viewDefinitions',
	initialState: viewDefinitionsAdapter.getInitialState(),
	reducers: {
		setViewDefinition: viewDefinitionsAdapter.setOne,
		deleteViewDefinition: viewDefinitionsAdapter.removeOne
	}
})

export const viewDefinitionActions = viewDefinitionsSlice.actions
export const viewDefinitionReducers = viewDefinitionsSlice.caseReducers
export const viewDefinitionsSelectors =
	viewDefinitionsAdapter.getSelectors<AppState>(state => state.viewDefinitions)
