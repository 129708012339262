import { createAction, createReducer } from '@reduxjs/toolkit'
import { logger } from 'lib/logger'
import type { TodoItem, TodoOperation, TodoOperationApplication } from 'lib/todotxtformat/model'
import TodoFileSerializer from 'lib/todotxtformat/todoFormat'
import { v4 as uuidv4 } from 'uuid'
import type { AppliedTodoOperationSet } from './model'
import { INITIAL_STATE, INITIAL_USER_SETTINGS } from './model'
import { viewDefinitionActions, viewDefinitionReducers } from './viewDefinitions'

export const SET_LAST_USED_FILTER = '@txtodo/SET_LAST_USED_FILTER'

export const startup = createAction('@txtodo/STARTUP')

export const setUser = createAction<{
	accessToken: string
	accessTokenExpiresAt: number
	refreshToken: string
	clientId: string
}>('@txtodo/SET_USER')

export const saveRefreshedAuth = createAction<any>('@txtodo/SAVE_REFRESHED_AUTH')

export const logout = createAction('@txtodo/LOGOUT')

export const mutateTodos = createAction<{
	ops: TodoOperation[]
}>('@txtodo/MUTATE_TODOS')

export const syncBegin = createAction(
	'@txtodo/SYNC_BEGIN',
	(payload: { skipIfSyncedSinceMs?: number; syncId?: string }) => ({
		payload: {
			skipIfSyncedSinceMs: payload.skipIfSyncedSinceMs,
			syncId: payload.syncId ?? uuidv4()
		}
	})
)

export const syncWork = createAction<{
	syncId: string
}>('@txtodo/SYNC_WORK')

export const syncComplete = createAction<{
	syncId: string
	todoFileContent: string
	todos: TodoItem[] | null
	localRevisionId: string | null
	remoteRevisionId: string
	hadConflict: boolean
	hadUnresolvableConflict: boolean
}>('@txtodo/SYNC_COMPLETE')

export const syncFail = createAction<{
	syncId: string
	code?: string
	message?: string
}>('@txtodo/SYNC_FAIL')

export const setLastUsedViewDefinition = createAction<{
	viewDefinitionId: string
}>('@txtodo/SET_LAST_USED_VIEW_DEFINITION')

export const { setViewDefinition } = viewDefinitionActions

export default createReducer(INITIAL_STATE, builder => {
	builder.addCase(setViewDefinition, (state, action) => {
		viewDefinitionReducers.setViewDefinition(state.viewDefinitions, action.payload)
	})

	builder.addCase(setUser, (state, { payload }) => {
		if (state.user) {
			logger.info('LOGIN: A user is already logged in, should log out first.')
			return
		}

		logger.info('LOGIN: Setting new user token.')

		state.user = {
			name: 'bob',
			accessToken: payload.accessToken,
			accessTokenExpiresAt: payload.accessTokenExpiresAt,
			refreshToken: payload.refreshToken,
			clientId: payload.clientId,
			settings: INITIAL_USER_SETTINGS
		}
		// state.router = '/home'
	})

	builder.addCase(saveRefreshedAuth, (state, { payload }) => {
		if (!payload) {
			logger.info('SAVE_REFRESHED_AUTH: payload falsy')
			return
		}

		if (!state.user) {
			logger.info('SAVE_REFRESHED_AUTH: user falsy')
			return
		}

		const refreshedAccessToken: string | undefined = payload.accessToken
		const { accessTokenExpiresAt } = payload

		if (refreshedAccessToken && accessTokenExpiresAt) {
			logger.info('SAVE_REFRESHED_AUTH: Saving refreshed accessToken')
			state.user.accessToken = refreshedAccessToken
			state.user.accessTokenExpiresAt = accessTokenExpiresAt
		}
	})

	builder.addCase(logout, (state, action) => {
		logger.info(`LOGOUT: Setting back to INITIAL_STATE`)
		return { ...INITIAL_STATE, router: '/login' }
	})

	builder.addCase(syncBegin, (state, action) => {
		if (!state.user || !state.user.accessToken) {
			logger.verbose(`SYNC: Not starting sync because user or token is falsy`)
			return
		}
		if (
			state.synchronization.inProgress &&
			state.synchronization.syncId !== action.payload.syncId
		) {
			logger.verbose(
				`SYNC: Not starting sync because another sync ${action.payload.syncId} is already in progress.`
			)
			return
		}

		if (
			action.payload.skipIfSyncedSinceMs &&
			Date.now() - state.synchronization.lastCompleteTime < action.payload.skipIfSyncedSinceMs &&
			state.synchronization.syncId !== action.payload.syncId
		) {
			logger.verbose(
				`SYNC: Not starting sync because last synchonization was at ${state.synchronization.lastCompleteTime}.`
			)
			return
		}

		logger.info(
			`SYNC: Will start. Setting state.synchronization: { inProgress: true, syncId: ${action.payload.syncId} }`
		)

		state.synchronization.syncId = action.payload.syncId
		state.synchronization.inProgress = true
	})

	builder.addCase(syncWork, (state, action) => {
		let { localRevisionId } = state.synchronization
		let lastHistoryEntryHasRemoteRevisionId = false
		if (localRevisionId) {
			logger.verbose(
				`SYNC: Use existing intermediate localRevisionId from previous sync attempt: ${localRevisionId}.`
			)
		} else if (state.history.length > 0) {
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			const lastHistoryEntry = (state.history as any).at(-1)
			if (lastHistoryEntry === undefined) {
				throw new Error('undefined lastHistoryEntry')
			}
			localRevisionId = lastHistoryEntry.localRevisionId
			lastHistoryEntryHasRemoteRevisionId = !!lastHistoryEntry.remoteRevisionId
			logger.verbose(`SYNC: Will use most recent localRevisionId from history: ${localRevisionId}.`)
		} else {
			logger.verbose(`SYNC: Will use empty localRevisionId.`)
		}

		let { remoteRevisionId } = state.synchronization
		if (remoteRevisionId) {
			logger.verbose(
				`SYNC: Will use existing intermediate remoteRevisionId from previous sync attempt.`
			)
		} else if (state.history.length > 0 && !lastHistoryEntryHasRemoteRevisionId) {
			const historyEntryWithRemoteRevision = [...state.history]
				.reverse()
				.find(aos => !!aos.remoteRevisionId)
			if (historyEntryWithRemoteRevision) {
				remoteRevisionId = historyEntryWithRemoteRevision.remoteRevisionId || null
				logger.verbose(
					`SYNC: Will attempt to write using most recent remoteRevisionId ${remoteRevisionId}.`
				)
			}
		} else {
			logger.verbose(`SYNC: Nothing to write, will read.`)
		}

		let { todos } = state.synchronization
		if (todos) {
			logger.verbose(`SYNC: will use existing intermediate todos from previous sync attempt.`)
		} else {
			todos = state.list
			logger.verbose(`SYNC: will use todos from active list.`)
		}

		logger.info(
			`SYNC: Setting sync inProgress to state with syncId=${action.payload.syncId},` +
				` localRevisionId=${localRevisionId}, hasRemoteRevisionId=${!!remoteRevisionId}`
		)

		state.synchronization.localRevisionId = localRevisionId
		state.synchronization.remoteRevisionId = remoteRevisionId
		state.synchronization.todos = todos
	})

	builder.addCase(syncComplete, (state, { payload }) => {
		if (state.history.some(h => h.remoteRevisionId === payload.remoteRevisionId)) {
			logger.verbose(
				`SYNC: Not changing list or history on sync complete because remoteRevisionId already in history`
			)
			state.synchronization = {
				...INITIAL_STATE.synchronization,
				lastCompleteTime: Date.now(),
				lastSyncedTodoFileContent: payload.todoFileContent
			}
			return
		}

		let lastStateLatestLocalRevision: string | null = null
		if (state.history.length > 0) {
			const lastEntry = (state.history as any).at(-1)
			if (lastEntry === undefined) {
				throw new Error('undefined in history with length > 0')
			}
			lastStateLatestLocalRevision = lastEntry.localRevisionId
		}

		if (lastStateLatestLocalRevision !== payload.localRevisionId) {
			logger.verbose(
				`SYNC: Will trigger another round of sync because ` +
					`new localRevisionId ${lastStateLatestLocalRevision} has supplanted ${payload.localRevisionId}`
			)

			state.synchronization.localRevisionId = lastStateLatestLocalRevision
			;(state.synchronization.remoteRevisionId = payload.hadConflict
				? 'deadbeefdeadbeef'
				: payload.remoteRevisionId),
				(state.synchronization.todos = state.list)
			state.synchronization.lastSyncedTodoFileContent = payload.todoFileContent
			return
		}

		if (state.history.length > 0) {
			const targetIndex = state.history.findIndex(
				t => t.localRevisionId === payload.localRevisionId
			)
			if (targetIndex === -1) {
				throw new Error(
					`Could not find matching localRevisionId ${payload.localRevisionId} in history`
				)
			}
			const historyEntryToUpdate = state.history[targetIndex]

			logger.verbose(
				`SYNC: Adding new remoteRevisionId to history entry at ` +
					`index ${targetIndex} with localRevisionId ${historyEntryToUpdate.localRevisionId}`
			)

			historyEntryToUpdate.remoteRevisionId = payload.remoteRevisionId
		} else {
			logger.verbose(`History is empty at sync complete so adding new entry.`)
			state.history.push({
				remoteRevisionId: payload.remoteRevisionId,
				localRevisionId: uuidv4(),
				operationApplications: [],
				localTime: Date.now()
			})
		}

		if (!payload.todos) {
			throw new Error(`consistency error: payload.todos is falsy`)
		}

		state.list = payload.todos
		state.synchronization = {
			...INITIAL_STATE.synchronization,
			lastCompleteTime: Date.now(),
			lastSyncedTodoFileContent: payload.todoFileContent
		}
	})

	builder.addCase(syncFail, (state, action) => {
		state.synchronization = {
			...INITIAL_STATE.synchronization,
			lastCompleteTime: state.synchronization.lastCompleteTime,
			lastSyncedTodoFileContent: state.synchronization.lastSyncedTodoFileContent
		}
	})

	builder.addCase(mutateTodos, (state, action) => {
		let todos: TodoItem[] = state.list
		let operationApplication: TodoOperationApplication
		const operationApplications: TodoOperationApplication[] = []

		const todoFormat = new TodoFileSerializer()
		for (const op of action.payload.ops) {
			;({ todos, operationApplication } = todoFormat.applyOperation(op, todos))
			operationApplications.push(operationApplication)
		}

		const appliedOperationSet: AppliedTodoOperationSet = {
			localRevisionId: uuidv4(),
			operationApplications,
			localTime: Date.now()
		}

		const indexOfLastRemoteRevision = [...state.history]
			.reverse()
			.findIndex(aos => !!aos.remoteRevisionId)
		if (indexOfLastRemoteRevision === -1) {
			throw new Error(`Could not find any remoteRevisionId when saving.`)
		} else {
			state.history = state.history
				.slice(Math.max(0, indexOfLastRemoteRevision - 99), state.history.length)
				.concat(appliedOperationSet)
		}

		logger.verbose(
			`Applied todolist mutation with ${appliedOperationSet.operationApplications.length} ops ` +
				` creating new localRevisionId ${appliedOperationSet.localRevisionId}`
		)

		state.list = todos
	})

	builder.addCase(setLastUsedViewDefinition, (state, action) => {
		if (state.interface.lastViewDefinitionId != action.payload.viewDefinitionId) {
			state.interface.lastViewDefinitionId = action.payload.viewDefinitionId
		}
	})
})
