import { v4 as uuidv4 } from 'uuid'

// prettier-ignore
export type TodoPriority = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K' 
| 'L' | 'M' | 'N' | 'O' | 'P' | 'Q' | 'R' | 'S' | 'T' | 'U' | 'V' | 'W' | 'X' 
| 'Y' | 'Z'
// export type TodoPriority = 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g' | 'h' | 'i' | 'j' | 'k'
// | 'l' | 'm' | 'n' | 'o' | 'p' | 'q' | 'r' | 's' | 't' | 'u' | 'v' | 'w' | 'x'
// | 'y' | 'z' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K'
// | 'L' | 'M' | 'N' | 'O' | 'P' | 'Q' | 'R' | 'S' | 'T' | 'U' | 'V' | 'W' | 'X'
// | 'Y' | 'Z'

export interface TodoItem {
	id: string

	lineNumber: number
	raw: string

	isCompleted: boolean
	priority: TodoPriority | null

	body: string

	projects: string[]
	contexts: string[]

	dueDate: string | null
	completedDate: string | null
}

export function buildTodoItem(item?: Partial<TodoItem>): TodoItem {
	// eslint-disable-next-line no-param-reassign
	item = item ?? {}
	return {
		id: item.id ?? uuidv4(),
		lineNumber: item.lineNumber === undefined ? -1 : item.lineNumber,
		raw: item.raw ?? '',
		isCompleted: item.isCompleted ?? false,
		priority: item.priority ?? null,
		body: item.body ?? '',
		projects: [...(item.projects ?? [])],
		contexts: [...(item.contexts ?? [])],
		dueDate: item.dueDate ?? null,
		completedDate: item.completedDate ?? null
	}
}

export enum TodoOperationType {
	SET_IS_COMPLETED = 'SET_IS_COMPLETED',

	UPSERT_TODO = 'UPSERT_TODO',

	DELETE_TODO = 'DELETE_TODO'
}

export interface BaseTodoOperation {
	type: string
}

export interface TodoOperationOnExisting extends BaseTodoOperation {
	todoId: string
}

export interface SetIsCompletedTodoOperation extends TodoOperationOnExisting {
	type: TodoOperationType.SET_IS_COMPLETED

	isCompleted: boolean
}

export interface DeleteTodoOperation extends TodoOperationOnExisting {
	type: TodoOperationType.DELETE_TODO
}

export interface UpsertTodoOperation extends BaseTodoOperation {
	type: TodoOperationType.UPSERT_TODO

	todo: TodoItem
}

export type TodoOperation = DeleteTodoOperation | SetIsCompletedTodoOperation | UpsertTodoOperation

export interface TodoOperationApplication {
	operation: TodoOperation
}
