import { isAnyOf } from '@reduxjs/toolkit'
import { DropboxFileProvider } from 'lib/fileproviders'
import { logger } from 'lib/logger'
import { ConflictResolvingSaver } from 'lib/todotxtformat/conflictResolver'
import TodoFileSerializer from 'lib/todotxtformat/todoFormat'
import {
	logout,
	mutateTodos,
	saveRefreshedAuth,
	setUser,
	syncBegin,
	syncComplete,
	syncFail,
	syncWork
} from '../reducers'
import type { AppStartListening } from '../store'

export default function addSagas(startListening: AppStartListening) {
	// on startup, login, or after any todos change,
	// dispatch action to see if we should start a synchronization
	startListening({
		matcher: isAnyOf(setUser, mutateTodos),
		effect: async (action, listenerApi) => {
			const state = listenerApi.getState()
			if (!!state.user && !!state.user.accessToken) {
				listenerApi.dispatch(syncBegin({}))
			}
		}
	})

	startListening({
		actionCreator: syncBegin,
		effect: async (action, listenerApi) => {
			const state = listenerApi.getState()
			if (
				!state.synchronization.inProgress ||
				action.payload.syncId !== state.synchronization.syncId
			) {
				logger.verbose(
					`SYNC: syncBegin Listener not dispatching syncWork. syncInProgress=${state.synchronization.inProgress}, action.syncId=${action.payload.syncId}, state.syncId=${state.synchronization.syncId}`
				)
			} else {
				logger.verbose(
					`SYNC: Dispatching syncWork because syncId ${action.payload.syncId} == ${state.synchronization.syncId}`
				)
				listenerApi.dispatch(
					syncWork({
						syncId: action.payload.syncId
					})
				)
			}
		}
	})

	startListening({
		actionCreator: syncWork,
		effect: async (action, listenerApi) => {
			const state = listenerApi.getState()

			if (!state.user || !state.synchronization.todos) {
				throw new Error(
					`SYNC: consistency error: user: ${!!state.user}, synchronization.todos: ${!!state
						.synchronization.todos}`
				)
			}

			const shouldWrite = !!state.synchronization.remoteRevisionId

			const fileProvider = new DropboxFileProvider(
				{
					accessToken: state.user.accessToken,
					accessTokenExpiresAt: state.user.accessTokenExpiresAt,
					refreshToken: state.user.refreshToken,
					clientId: state.user.clientId
				},
				logger.info
			)

			if (shouldWrite) {
				const conflictResolver = new ConflictResolvingSaver(fileProvider, logger.info)

				if (
					!state.synchronization.remoteRevisionId ||
					!state.synchronization.lastSyncedTodoFileContent
				) {
					logger.warn(
						`SYNC: Consistency error- remoteRevisionId:${state.synchronization.remoteRevisionId}, lastSyncedTodoFileContent: ${state.synchronization.lastSyncedTodoFileContent}`
					)
					listenerApi.dispatch(syncFail({ syncId: action.payload.syncId }))
					return
				}

				let errorCode = 'unknownSyncWriteError'
				let errorMessage = 'Unknown Sync Write error'

				try {
					const saveResult = await conflictResolver.saveTodos(
						state.user.settings.todoFilePath,
						state.synchronization.remoteRevisionId,
						state.synchronization.todos,
						state.synchronization.lastSyncedTodoFileContent
					)

					if (!saveResult.hadUnresolvableConflict) {
						listenerApi.dispatch(
							syncComplete({
								syncId: action.payload.syncId,
								localRevisionId: state.synchronization.localRevisionId || '',
								remoteRevisionId: saveResult.cloudFile.version,
								todos: saveResult.todos,
								todoFileContent: saveResult.cloudFile.content,
								hadConflict: saveResult.hadConflict,
								hadUnresolvableConflict: saveResult.hadUnresolvableConflict
							})
						)
						return
					}
					errorCode = 'conflict'
					errorMessage = 'Sync unresolvable conflict'
				} catch (error) {
					logger.warn(`SYNC: Failed ${error}`)
					logger.warn(error)
					if (error instanceof Error) {
						errorCode = error.toString()
						errorMessage = error.message
					}
				}
				listenerApi.dispatch(
					syncFail({
						syncId: action.payload.syncId,
						code: errorCode,
						message: errorMessage
					})
				)
			} else {
				const serializer = new TodoFileSerializer()

				try {
					const { result, refreshedAuth } = await fileProvider.getFile(
						state.user.settings.todoFilePath
					)

					if (refreshedAuth) {
						listenerApi.dispatch(saveRefreshedAuth(refreshedAuth))
					}

					listenerApi.dispatch(
						syncComplete({
							syncId: action.payload.syncId,
							localRevisionId: state.synchronization.localRevisionId,
							todos: serializer.fromFile(result.content),
							remoteRevisionId: result.version,
							hadConflict: false,
							hadUnresolvableConflict: false,
							todoFileContent: result.content
						})
					)
				} catch (error: unknown) {
					if (error instanceof Error && error.message == '401') {
						listenerApi.dispatch(logout())
					}

					if (error instanceof Error && error.message === 'notfound') {
						logger.info(
							'SYNC: Got a notfound response while retrieving todo file, will create an initial file'
						)
						if (!state.user) {
							throw new Error('consistency error')
						}
						const content = '(A) This is your first example todo item'
						try {
							const { result, refreshedAuth } = await fileProvider.putFile(
								state.user.settings.todoFilePath,
								{
									content,
									version: null
								}
							)
							if (
								!state.user ||
								!state.synchronization.localRevisionId ||
								!state.synchronization.remoteRevisionId
							) {
								throw new Error('consistency error')
							}
							if (refreshedAuth) {
								listenerApi.dispatch(saveRefreshedAuth(refreshedAuth))
							}
							listenerApi.dispatch(
								syncComplete({
									syncId: action.payload.syncId,
									localRevisionId: state.synchronization.localRevisionId,
									todos: serializer.fromFile(content),
									remoteRevisionId: result,
									hadConflict: false,
									hadUnresolvableConflict: false,
									todoFileContent: content
								})
							)
							return
						} catch (newFileError) {
							logger.warn(newFileError)
							listenerApi.dispatch(syncFail({ syncId: action.payload.syncId }))
						}
					} else {
						listenerApi.dispatch(syncFail({ syncId: action.payload.syncId }))
					}
				}
			}
		}
	})

	startListening({
		actionCreator: syncComplete,
		effect: async ({ payload }, listenerApi) => {
			const state = listenerApi.getState()
			if (!state.synchronization.inProgress || payload.syncId !== state.synchronization.syncId) {
				logger.verbose(
					`SYNC: completeSync Listener WON'T start a new sync because sync inProgress==${state.synchronization.inProgress} or ` +
						`action syncId "${payload.syncId}" != "${state.synchronization.syncId}"`
				)
				return
			}

			const lastHistoryEntry = state.history.at(-1)

			if (!lastHistoryEntry.remoteRevisionId) {
				logger.verbose(
					`SYNC: completeSync Listener WILL start a new sync because newest localRevision ` +
						`${lastHistoryEntry.localRevisionId} has no remoteRevisionId `
				)
				listenerApi.dispatch(syncWork({ syncId: payload.syncId }))
			}
		}
	})

	startListening({
		actionCreator: syncFail,
		effect: async ({ payload }, listenerApi) => {
			alert(`sync failed: ${payload.message}`)
		}
	})
}
