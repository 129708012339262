import Box from '@mui/material/Box'
import type { ReactElement } from 'react'

interface Properties {
	error?: Error
}
export default function LoadingOrError({ error }: Properties): ReactElement {
	return (
		<Box display='flex' justifyContent='center' alignItems='center' minHeight='100vh'>
			<h1 className='text-xl' data-testid='LoadingOrError'>
				{error ? error.message : 'Loading...'}
			</h1>
		</Box>
	)
}
LoadingOrError.defaultProps = {
	error: undefined
}
