import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { createBrowserHistory } from 'history'

const browserHistory = createBrowserHistory() // ({ basename: '' })
const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
	config: {
		connectionString:
			'InstrumentationKey=cf10994d-3f29-481a-814a-7cc3a95d2eae;IngestionEndpoint=https://eastus-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/',
		extensions: [reactPlugin],
		// enableAutoRouteTracking: true,
		extensionConfig: {
			[reactPlugin.identifier]: { history: browserHistory }
		}
	}
})
appInsights.loadAppInsights()

export { reactPlugin, appInsights }
